@import url(https://fonts.googleapis.com/css2?family=Signika:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --backgroundColor : #fdfdff;
  --accentColor : #69AFEE;
  --boxShadow : 1px 1px 2px rgba(0, 0, 0, 0.192), 3px 3px 5px rgba(0, 0, 0, 0.096);
}

body {
  font-family: 'Signika', sans-serif;
  
}
#room {
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: flex-start;
  height:100vh;width:100vw;
  overflow: hidden;
}
.centralPanel {
  height:100vh;
flex-grow: 1;
  display:flex;
  flex-direction: column;
}
#playerList {
  height:100vh;
  display:block; 
  width:20vw;
  background-color:  #fdfdff;
  background-color:  var(--backgroundColor);
  margin-top: 15px;
  margin-left: 15px;
}
#userRole {
  background-color: #fdfdff;
  background-color: var(--backgroundColor);
  flex-grow:2;
  display: flex;
}
#userRole .placeholder {
  font-size: 1.5em;
      text-align: center;
}
#roleList .placeholder {
    display: block;
    text-align: center;
    margin-top: 15px;
}
.placeholder {
  color: rgb(174, 181, 198);
  margin: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
}

#addRole {
    padding: 0px 15px;
  background-color:  #fdfdff;
  background-color:  var(--backgroundColor);
  margin-top: 15px;
}

.rightPanel {
  background-color:  #fdfdff;
  background-color:  var(--backgroundColor);
  height:100vh;
  display:block; 
  width:35vw;
  box-sizing: border-box;
  display: flex;
flex-direction: column;
}

#roleList {
    padding: 0px 15px;
    overflow: auto;
      flex-grow:1;
}

#roleList .rien {
  color: #CCC;
  text-align:center;
}

.tile {
  display:block;
  background-color:  #fdfdff;
  background-color:  var(--backgroundColor);
  box-shadow:  1px 1px 2px rgba(0, 0, 0, 0.192), 3px 3px 5px rgba(0, 0, 0, 0.096);
  box-shadow:  var(--boxShadow);
  position: relative;
  margin: 10px 0;
}
.directed {
  font-style: italic;
}

.tile .name {
  font-size: 1.4em;
  font-weight: bold;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  width: 100%;
  background-color: #8AB1D4;
  display: block;
  padding: 5px 20px;
  box-sizing: border-box;
  color: #fdfdff;
  color: var(--backgroundColor);
}

.tile .description {
  padding: 5px 10px;
  display: block;
}

.tile .delete {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.7em;
  cursor: pointer;
  display: none;
  color: #a00;
}

.tile:hover > .delete {
  display: block;
}

.addRoleForm .name, .addRoleForm .description {
  font-family: inherit;
	width: 100%;
	box-sizing: border-box;
	font-size: 1.5em;
	padding: 5px;
	border: none;
	border-bottom: 4px #65829B solid;
  transition: background-color .2s ease-in-out;
  margin:0;
}
.addRoleForm .description{
	font-size: 1.1em;
  resize: vertical;
  height: 15vh;
}

.addRoleForm .name:hover, .addRoleForm .description:hover {
  background-color: #f3f3f5;
  
}

h1 {
  background-color: #2f5e88;
  color: #fdfdff;
  color: var(--backgroundColor);
  padding: 1px;
  text-align: center;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  margin: 0;
}

.submit {
    background-color:  #69AFEE;
    background-color:  var(--accentColor);
    border: 0;
    color: #FFF;
    font-size: 1.5em;
    border-radius: 3px;
    padding: 10px 75px;
    margin: 15px auto;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.192), 3px 3px 5px rgba(0, 0, 0, 0.096);
    box-shadow: var(--boxShadow);
    transition: all .2s cubic-bezier(.42,0,.4,1.69);
    cursor: pointer;
    display: block;
}


.submit:hover {
  background-color: #69AFEE;
}


/* The container */
.checkboxContainer {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 15px auto;
}



/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  transition: all .2s ease-in-out;
border: 1px  #fdfdff solid;
border: 1px  var(--backgroundColor) solid;
border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color:  #69AFEE;
  background-color:  var(--accentColor);;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


#roll {
	flex-grow: 1;
	display: flex;
  background-color: #fdfdff;
  background-color: var(--backgroundColor);
  flex-direction: column;
}

#roll span {
  margin:auto;
}

.minMaxContainer {
  text-align: center;
  font-size:1.2em;
}

.minMaxContainer input {
  font-size:1.2em;
  width: 2em;
  margin: 0px 10px;
}


.usernameForm {
    display:flex;
  background-color: #fdfdff;
  background-color: var(--backgroundColor);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
}
.title {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.title p { /* MARB */
  margin:auto;
  font-size:3em;
  font-weight: bold;
background: linear-gradient(110deg, rgba(85,152,212,1) 0%, rgba(98,167,229,1) 34%, rgba(230,243,255,1) 36%, rgba(98,167,228,1) 38%, rgba(85,152,212,1) 100%); 

-webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: titleAnim 4s infinite alternate ease-in-out ;
  background-size: 200%;
}

@keyframes titleAnim {
  0% {
    background-position-x: right;
  }
  100% {
        background-position-x: left;

  }
}

.title span { /* Bienvenue sur */
  margin: 100px auto 15px;
  font-size:1em;
  color: rgb(109, 109, 109)
}
.nameForm {
  flex: 2 1
}
.usernameForm div {
  margin:auto;
}
.usernameForm div input, .usernameForm div button{
  font-size: 1.8em;
}
.usernameForm div input {
  font-family: inherit;
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
	border: none;
	border: 1px #65829b31 solid;
	border-bottom: 4px #65829B solid;
  transition: background-color .2s ease-in-out;
  margin:0;
}

.usernameForm div input:hover {
  background-color: #f3f3f5;
  
}

.roleCard {
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 80%;
}

.roleCard section {
	display: flex;
	flex-direction: column;
	width: 100%;
  box-shadow:  1px 1px 2px rgba(0, 0, 0, 0.192), 3px 3px 5px rgba(0, 0, 0, 0.096);
  box-shadow:  var(--boxShadow);
}

.preRole {
  font-size: 1.8em;
  color:#adb3b8;
  font-weight:bold;
  font-style: italic;
  align-self: flex-start;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.role {
  font-size: 2.2em;
  background-color: #69AFEE;
  background-color: var(--accentColor);
  color: #fdfdff;
  color: var(--backgroundColor);
  padding: 8px 35px;
  width:100%;
  text-align: center;
  box-sizing: border-box;
}
.cible {
  font-size: 1.4em;
  width:100%;
  padding: 4px 35px;
  text-align: center;
  box-sizing: border-box;
  background-color: aliceblue;
}

.roleCard .description {
  font-size: 1.2em;
  width:100%;
  padding: 10px 35px;
  text-align: center;
  box-sizing: border-box;
  background-color: #FFF;

}
